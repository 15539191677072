import { tv, type VariantProps } from 'tailwind-variants'

export const typographyVariants = tv(
  {
    base: 'block',
    variants: {
      align: {
        inherit: 'txt-inherit',
        left: 'text-left',
        center: 'text-center',
        right: 'text-right'
      },
      noWrap: {
        true: 'truncate'
      },
      color: {
        default: '',
        error: '!text-error [&>*]:text-error',
        shy: 'text-black/50'
      },
      family: {
        body: 'font-body',
        brand: 'font-zoi'
      },
      variant: {
        h1: '',
        h2: '',
        h3: '',
        h4: '',
        h5: '',
        h6: '',
        body1: 'text-body1',
        body2: 'text-body2',
        subtitle1: 'text-subtitle1',
        subtitle2: 'text-subtitle2',
        mention: 'text-mention'
      }
    },
    compoundVariants: [
      {
        family: 'body',
        variant: 'h1',
        class: 'text-body-h1'
      },
      {
        family: 'body',
        variant: 'h2',
        class: 'text-body-h2'
      },
      {
        family: 'body',
        variant: 'h3',
        class: 'max-md:text-body-h4 text-body-h3'
      },
      {
        family: 'body',
        variant: 'h4',
        class: 'max-md:text-body-h5 text-body-h4'
      },
      {
        family: 'body',
        variant: 'h5',
        class: 'max-md:text-body-h6 text-body-h5'
      },
      {
        family: 'body',
        variant: 'h6',
        class: 'text-body-h6'
      },
      {
        family: 'brand',
        variant: 'h1',
        class: 'max-md:text-brand-h3 text-brand-h1'
      },
      {
        family: 'brand',
        variant: 'h2',
        class: 'max-sm:text-brand-h4 max-md:text-brand-h3 text-brand-h2'
      },
      {
        family: 'brand',
        variant: 'h3',
        class: 'text-brand-h3'
      },
      {
        family: 'brand',
        variant: 'h4',
        class: 'max-md:text-brand-h5 text-brand-h4'
      },
      {
        family: 'brand',
        variant: 'h5',
        class: 'text-brand-h5'
      },
      {
        family: 'brand',
        variant: 'h6',
        class: 'text-brand-h6'
      }
    ],
    defaultVariants: {
      variant: 'body1',
      family: 'body',
      color: 'default',
      align: 'inherit'
    }
  },
  {
    twMerge: false
  }
)

export type Variants = VariantProps<typeof typographyVariants>
