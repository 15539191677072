import React from 'react'
import { type HeadingTag, headingTagsMapping } from '~/constants/headings'
import { cn } from '~/utils/cn'
import * as styles from './Typography.styles'

export type TypographyProps<T extends IntrinsicElement> = {
  component?: T
} & JSX.IntrinsicElements[T] &
  styles.Variants

const Typography = <T extends IntrinsicElement = 'p'>({
  variant,
  className = '',
  align,
  noWrap,
  color,
  family,
  component = '' as T,
  ...restProps
}: TypographyProps<T>) => {
  const finalComponent = component || headingTagsMapping[variant as HeadingTag]

  return React.createElement(finalComponent || 'p', {
    className: cn(
      styles.typographyVariants({
        variant,
        align,
        noWrap,
        color,
        family
      }),
      className
    ),
    ...restProps
  })
}

export default Typography
